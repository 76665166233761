import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';

const _self = (state: RootState) => state.auth;

// export const selectUser = createSelector([_self], ({ user }) => user);
export const selectUser = createSelector([_self], ({ user }) => user);
export const selectToken = createSelector([_self], ({ token }) => token);
export const selectIsLogged = createSelector([_self], ({ isLogged }) => isLogged);
export const selectAccessToken = createSelector([selectToken], (accessToken) => accessToken);
export const selectCacheTokens = createSelector([_self], ({ cacheTokens }) => cacheTokens);
export const selectSelectedCard = createSelector([_self], ({ selectedCard }) => selectedCard);
// export const selectIsSessionLoading = createSelector([_self], ({ isSessionLoading }) => isSessionLoading);
