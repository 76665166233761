import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from 'features/api/apiSlice';
import { setCachedToken } from 'features/auth/authSlice';
import { selectCacheTokens } from 'features/auth/selectors';
import { RootState } from 'lib/centralStore';

export const getExternalHasedToken = createAsyncThunk(
  'getExternalHasedToken',
  async (
    { codicePiattaforma, codeGame, isInFrame }: { codicePiattaforma: number; codeGame?: string; isInFrame: boolean },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const cachedToken = selectCacheTokens(state)[codicePiattaforma];

    if (cachedToken && isInFrame) {
      return Promise.resolve(cachedToken);
    }

    try {
      return await dispatch(
        apiSlice.endpoints.getExternalToken.initiate({ codicePiattaforma, codeGame }, { forceRefetch: true })
      )
        .unwrap()
        .then(({ externalTokenSgp: externalToken }) => {
          if (externalToken) {
            dispatch(setCachedToken({ [codicePiattaforma]: externalToken }));
            return externalToken;
          }
          return null;
        });
    } catch (error) {
      console.error(`There was an error`, error);
      return null;
    }
  }
);
