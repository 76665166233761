import { SUPPLIER_LOTTOMATICA_MODEL_API_KEY, SUPPLIER_SISAL_MODEL_API_KEY } from 'lib/datoCms/constants';
import { GRATTA_E_VINCI_SUPPLIER, LOTTOMATICA_SUPPLIER, SISAL_SUPPLIER } from 'utility/constant';
import { gtag, isClientSide, isMatch } from 'utility/functions';
import { GamePlatformType, LaunchGameType } from './types';

import { appInsight } from 'components/appInsight';
import { apiSlice } from 'features/api/apiSlice';
import { RootState } from 'lib/centralStore';
import { store } from 'sites/snai/lib/store';
import { getExternalHasedToken } from './actions/actions';
import { CasinoLiveLaunch } from './types/casinoLiveLaunch';
import { requestGeoLocation } from 'features/geoComply/actions';

export interface OpenCenterModalProps {
  url: string;
  target?: string;
  window?: Window;
  widthSize?: number | string;
  heightSize?: number | string;
}

export const openCenterModal = ({ url, target, widthSize, heightSize, window: openedWindow }: OpenCenterModalProps) => {
  if (isClientSide()) {
    const { width: w, height: h } = window.screen;
    const state = store.getState() as RootState;

    if (state.configuration.isSlotTracingEnabled) {
      appInsight.trackEvent({
        name: 'Launch slot in modal',
        properties: {
          url,
          target,
          widthSize,
          heightSize,
        },
      });
      console.log(`[LAUNCH] - LAUNCHING SLOT IN MODAL`, url);
    }

    if (openedWindow) {
      openedWindow.location = url;
    } else {
      const width = widthSize === '100%' ? w : widthSize ?? w * 0.5;
      const height = heightSize === '100%' ? h : heightSize ?? h * 0.5;
      const topPosition = (h - Number(`${height}`)) * 0.5;
      const leftPosition = (w - Number(`${width}`)) * 0.5;
      const features = `fullscreen=0,titlebar=0,toolbar=0,location=yes,status=0,menubar=0,scrollbars=0,resizable=0,width=${width},height=${height},left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition}`;
      const openingType = target ?? '_blank';

      const element = document.createElement('a');
      element.style.display = 'none';
      element.setAttribute('onClick', `window.open('${url}', '${openingType}', '${features}')`);
      element.click();
      setTimeout(() => {
        element.remove();
      }, 100);
    }
  }
};

// TODO : REVIEW ALL DESTRUCTUGIN PROPETY: keep used props, skipp unused ones
export const gamePlatforms: GamePlatformType = {
  151: {
    urlParams: {
      FIM_CONF: process.env.NEXT_PUBLIC_LAUNCH_FIM_PLAYTECH_CASINO!,
      GAME_URL: `${process.env.NEXT_PUBLIC_LAUNCH_SNAISKILL}?`,
    },
    async launchGame({ codicePiattaforma, gameCode, isDemo, username, cardNumber, provider, ...others }) {
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = (await getToken(codicePiattaforma, gameCode, isInFrame))!;
      const url =
        gamePlatforms[151].urlParams.GAME_URL +
        new URLSearchParams({
          username,
          password: token!,
          game_id: gameCode,
          demo: isDemo ? '1' : '',
          provider: others.idProvider?.toString() ?? '',
        });

      if (isInFrame) {
        return Promise.resolve(url);
      }

      openCenterModal({
        url,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  158: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_MICROGAME}`,
      SESSION_DURATION: '30',
      SCHEMA: 'HTTPS',
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, gameMode, username, ...others }) => {
      const platform = gamePlatforms[158];
      const { urlParams } = platform;
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = (await getToken(codicePiattaforma, gameCode, isInFrame))!;

      const url = `${urlParams.FIM_CONF}`;
      const params = new URLSearchParams({
        Username: others.cardNumber,
        SessionToken: token,
        SessionDuration: urlParams.SESSION_DURATION,
        Action: isDemo ? 'forfun' : 'forreal',
        ProductCode: 'CASINO',
        Schema: urlParams.SCHEMA,
        GameCode: gameCode,
      });

      // TODO typeof controll because incur an error
      // TODO check if gameMode can be other of string
      if (typeof gameMode === 'string' && gameMode.toUpperCase() === 'CASINO') {
        params.append('Paramiters', `params=lobbyUrl=${process.env.NEXT_PUBLIC_LAUNCH_LAUNCH_LOBBY_URL}`);
      }

      if (isInFrame) {
        return Promise.resolve(`${url}?${params}`);
      }
      // setTimeout(() => {
      //   window.open(`${url}?${params}`, '_blank', `width=${others.dimension.width}, height=${others.dimension.height}`);
      // });
      // return;
      openCenterModal({
        url: `${url}?${params}`,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  159: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_CAPECOD}`,
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, ...others }) => {
      let token: string;
      const isInFrame = others.isInstant && others.isFromPanel;

      if (!isDemo) {
        token = (await getToken(codicePiattaforma, gameCode, isInFrame))!;
      }
      const platform = gamePlatforms[159];
      const { urlParams } = platform;

      const url = `${urlParams.FIM_CONF!}?${new URLSearchParams({
        GAMEID: gameCode,
        FORFUN: isDemo ? '1' : '',
        CASHMODE: isDemo ? '2' : '',
        p: !isDemo ? token! : '',
      })}`;

      if (isInFrame) {
        return Promise.resolve(url);
      }

      // setTimeout(() => {
      //   window.open(url, '_blank', `width=${others.dimension.width}, height=${others.dimension.height}`);
      // });
      // return;
      openCenterModal({
        url,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  161: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_GAN}`,
      LANGUAGE: 'it',
      URI_GA: 'popPartnerGame.do',
    },
    launchGame: async ({ codicePiattaforma, gameCode, provider, isDemo, ...others }) => {
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = (await getToken(codicePiattaforma, gameCode, isInFrame))!;
      const platform = gamePlatforms[161];
      const { urlParams } = platform;

      // THIS SLOT CAN BE LAUNCHED AS FOR FUN ONLY IF THE PROVIDER IS IGT
      const mode = provider?.toUpperCase() === 'IGT' ? (isDemo ? 'forfun' : 'real') : 'real';
      const url = `${urlParams.FIM_CONF}/${gameCode}/${urlParams.URI_GA}?launch=${mode}&`;
      const params = new URLSearchParams({
        token,
        lang: urlParams.LANGUAGE,
      });

      if (isInFrame) {
        return Promise.resolve(`${url}${params}`);
      }
      // setTimeout(() => {
      //   window.open(`${url}${params}`, '_blank', `width=${others.dimension.width}, height=${others.dimension.height}`);
      // });
      // return;
      openCenterModal({
        url: `${url}${params}`,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  162: {
    urlParams: {
      CASINO_URL: `${process.env.NEXT_PUBLIC_LAUNCH_GOL_SLOT_CASINO}`,
      SKILL_URL: `${process.env.NEXT_PUBLIC_LAUNCH_GOL_GIOCHI}`,
      ID_NETWORK: '1',
      LANGUAGE: 'it',
    },
    async launchGame({ codicePiattaforma, gameCode, isDemo, ...others }) {
      const { urlParams } = gamePlatforms[162];
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = await getToken(codicePiattaforma, gameCode, isInFrame);
      const url = others.product === 'skill' || others.product === 'cash' ? urlParams.SKILL_URL : urlParams.CASINO_URL;

      const params = new URLSearchParams({
        id_network: urlParams.ID_NETWORK,
        token: token!,
        lang: urlParams.LANGUAGE,
        CAMP1: '',
        CAMP2: '',
        gioco: gameCode,
        mod: isDemo ? 'F' : 'M',
        prodotto: others.product === 'cash' ? 'skill' : others.product,
      });

      if (others.product === 'skill') {
        params.append('tipo', 'S');
      }

      if (others.product === 'cash') {
        params.append('tipo', 'C');
      }

      if (isInFrame) {
        return Promise.resolve(`${url}?${params}`);
      }

      if (isClientSide()) {
        openCenterModal({
          url: `${url}?${params}`,
          heightSize: others?.dimension?.height,
          widthSize: others?.dimension?.width,
          window: others.window,
        });
      }
      return Promise.resolve();
    },
  },
  163: {
    urlParams: {
      LAUNCH_URL: `${process.env.NEXT_PUBLIC_LAUNCH_NEWURL_PLAYTECH_CASINO}?`,
      LANGUAGE: 'it',
      PT_NGM: '1',
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, isInstant, cardNumber, ...others }) => {
      const { urlParams } = gamePlatforms[163];
      const isInFrame = isInstant && others.isFromPanel;
      const externalToken = (await getToken(codicePiattaforma, gameCode, isInFrame))!;
      const casinoMode =
        others?.gameMode === 'Poker' ? 'ptstaging1.26' : `${process.env.NEXT_PUBLIC_LAUNCH_NEW_PLAYTECH_CASINOENV}`;

      const params = new URLSearchParams({
        gameCodeName: gameCode,
        username: cardNumber || '',
        externalToken,
        casino: casinoMode,
        clientPlatform: others.isMobile ? 'mobile' : 'web',
        language: urlParams.LANGUAGE,
        real: isDemo ? '0' : '1',
        playMode: isDemo ? '0' : '1',
      });

      if (isInstant && !others.isMobile) {
        params.append('runtype', 'mini');
      }

      const url = urlParams.LAUNCH_URL + params;

      if (isInFrame) {
        return Promise.resolve(url);
      }
      openCenterModal({
        url: urlParams.LAUNCH_URL + params,
        target: 'gioco',
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  164: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_BETPOINT}`,
      BP_SKIN: 'SNAI',
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, username, ...others }) => {
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = (await getToken(codicePiattaforma, gameCode, isInFrame))!;
      const platform = gamePlatforms[164];
      const { urlParams } = platform;

      const params = new URLSearchParams({
        game: gameCode,
        skin: urlParams.BP_SKIN,
        mode: isDemo ? 'fun' : 'real',
        username,
      });

      if (!isDemo) {
        params.append('token', token!);
      }

      const url = `${urlParams.FIM_CONF!}?${params}`;

      if (isInFrame) {
        return Promise.resolve(url);
      }

      // setTimeout(() => {
      //   window.open(url, '_blank', `width=${others.dimension.width}, height=${others.dimension.height}`);
      // });
      // return;
      openCenterModal({
        url,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  165: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_GAME_360}`,
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, username, contractCode, cardNumber, ...others }) => {
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = await getToken(codicePiattaforma, gameCode, isInFrame);
      const platform = gamePlatforms[165];
      const { urlParams } = platform;
      const params = new URLSearchParams({
        gamecode: gameCode!,
        countryid: 'IT',
        language: 'it',
        gamemode: isDemo ? 'demo' : 'real',
        accountid: contractCode!,
        nickname: username!,
        usersessionid: cardNumber,
      });

      if (!isDemo) {
        params.append('token', token!);
      }

      const url = `${urlParams.FIM_CONF!}?${params}`;

      if (isInFrame) {
        return Promise.resolve(url);
      }

      openCenterModal({
        url,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
  167: {
    urlParams: {
      FIM_CONF: `${process.env.NEXT_PUBLIC_LAUNCH_ISOFTBET}`,
      ISOFTBET_LICENSE_ID: 147,
    },
    launchGame: async ({ codicePiattaforma, gameCode, isDemo, username, ...others }) => {
      const isInFrame = others.isInstant && others.isFromPanel;
      const token = await getToken(codicePiattaforma, gameCode, isInFrame);
      const platform = gamePlatforms[167];
      const { urlParams } = platform;
      const params = new URLSearchParams({
        lang: 'it',
        cur: 'EUR',
        mode: isDemo ? '0' : '1',
      });

      if (!isDemo) {
        params.append('user', username!);
        params.append('uid', username!);
        params.append('token', token!);
      }

      const url = `${urlParams.FIM_CONF!}/${urlParams.ISOFTBET_LICENSE_ID}/${gameCode}?${params}`;

      if (isInFrame) {
        return Promise.resolve(url);
      }

      // setTimeout(() => {
      //   window.open(url, '_blank', `width=${others.dimension.width}, height=${others.dimension.height}`);
      // });
      // return;
      openCenterModal({
        url,
        heightSize: others?.dimension?.height,
        widthSize: others?.dimension?.width,
        window: others.window,
      });
      return Promise.resolve();
    },
  },
};

export async function launchGame({
  codicePiattaforma,
  gameCode,
  isDemo,
  isInstant,
  username,
  cardNumber,
  ...other
}: LaunchGameType) {
  console.debug(`[LAUNCH] - LAUNCHING GAME`, gameCode, codicePiattaforma);
  let w: Window | undefined = undefined;

  if (isClientSide() && !isInstant) {
    const slotDimensions = {
      height: other.dimension?.height ?? 500,
      width: other.dimension?.width ?? 500,
    };
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width =
      slotDimensions.width === '100%' ? window.screen.width : +slotDimensions.width ?? window.screen.width * 0.5;
    const height =
      slotDimensions.height === '100%' ? window.screen.height : +slotDimensions.height ?? window.screen.height * 0.5;

    const systemZoom = window.innerWidth / window.screen.availWidth;
    const left = (window.innerWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (window.innerHeight - height) / 2 / systemZoom + dualScreenTop;
    const features = `fullscreen=0,titlebar=0,toolbar=0,location=yes,status=0,menubar=0,scrollbars=0,resizable=0,width=${width},height=${height},left=${left},top=${top},screenX=${left},screenY=${top}`;
    w = window.open(`/game-loading`, '_blank', features)!;
    store.dispatch(requestGeoLocation('GamePlay'));
  }
  gtag({ section: 'Quick Slot', detail: gameCode, event: 'Launch Game' }, true);
  return gamePlatforms[codicePiattaforma as keyof typeof gamePlatforms].launchGame({
    codicePiattaforma,
    gameCode,
    isDemo,
    isInstant,
    username,
    cardNumber,
    ...other,
    window: w,
  });
}

export async function login(codicePiattaforma: number, cardNumber: string, codeGame: string) {
  const externalToken = await getToken(codicePiattaforma, codeGame);
  //@ts-ignore
  iapiSetDeviceFamily('desktop');
  //@ts-ignore
  iapiSetAuthenticationType('externalToken');
  //@ts-ignore
  iapiLogin(cardNumber, externalToken, '1', 'it');

  return externalToken;
}

export async function getToken(codicePiattaforma: number, codeGame?: string, isInFrame: boolean = false) {
  const status = await store.dispatch(getExternalHasedToken({ codicePiattaforma, codeGame, isInFrame }));
  return status.payload as string;
}

export async function getExternalPlainToken(codicePiattaforma: number) {
  try {
    return await store
      .dispatch(apiSlice.endpoints.getExternalToken.initiate({ codicePiattaforma }, { forceRefetch: true }))
      .unwrap()
      .then((response) => {
        return response.externalToken;
      });
  } catch (error) {
    console.error(`There was an error`, error);
    return null;
  }
}

export async function launchGameBingo(
  gameName: string,
  token: string,
  frameDimension: {
    height: number;
    width: number;
  },
  isMobile: boolean
) {
  var playUrl = `${
    isMobile ? process.env.NEXT_PUBLIC_LAUNCH_BINGO_MOBILE : process.env.NEXT_PUBLIC_LAUNCH_BINGO
  }?gameName=${gameName}&gameType=Bingo&region=it&token=${token}`;

  openCenterModal({ url: playUrl, heightSize: frameDimension?.height, widthSize: frameDimension?.width });

  return Promise.resolve();
}

export async function launchGameBingoLive(url: string, frameDimension: { height: number; width: number }) {
  openCenterModal({ url, heightSize: frameDimension?.height, widthSize: frameDimension?.width });
  return Promise.resolve();
}

export async function launchGameCasinoLive({ imsGameType, id, isMobile, launchAlias, cardNumber }: CasinoLiveLaunch) {
  if (isClientSide()) {
    const path = `${process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_DESKTOP}?`;
    const gameCode = `${imsGameType};${launchAlias[0]}`;

    const externalToken = (await getToken(163))!;

    const params = new URLSearchParams({
      gameCodeName: gameCode,
      username: cardNumber || '',
      externalToken,
      casino: `${process.env.NEXT_PUBLIC_LAUNCH_NEW_PLAYTECH_CASINOENV}`,
      clientPlatform: isMobile ? 'mobile' : 'web',
      language: 'it',
      playMode: '1',
    });

    openCenterModal({
      url: path + params,
      target: 'gioco',
    });
    return Promise.resolve();
  }

  return Promise.resolve();
}

const getCodicePiattaformaBySupplierType = (supplierType: string): number => {
  if (isMatch(supplierType, `^${SUPPLIER_SISAL_MODEL_API_KEY}`)) {
    return SISAL_SUPPLIER;
  } else if (isMatch(supplierType, `^${SUPPLIER_LOTTOMATICA_MODEL_API_KEY}$`)) {
    return LOTTOMATICA_SUPPLIER;
  }
  return GRATTA_E_VINCI_SUPPLIER;
};

export async function getTokenBySupplierType(supplierType: string) {
  const codicePiattaforma = getCodicePiattaformaBySupplierType(supplierType);

  const externaltoken = await getExternalPlainToken(codicePiattaforma);

  return externaltoken;
}

async function getTokenAsync() {
  try {
    return await store
      .dispatch(
        apiSlice.endpoints.getExternalTokenPoker.initiate(null, {
          forceRefetch: true,
        })
      )
      .unwrap();
  } catch (error) {
    console.error(`Error during retrieving token`, error);
    return null;
  }
}

export async function launchGamePoker(
  cardNumber: string,
  frameDimension?: {
    height: number;
    width: number;
  }
) {
  let token = await getExternalPlainToken(160);
  let obj2 = await getTokenAsync();
  let playUrl = `${process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_POKER}&loginexternalsessiontoken=${token}`;

  let realMode = 1;

  function loginPoker(snaicard: string) {
    //@ts-ignore
    iapiLogin(snaicard, obj2?.token, realMode, 'it');
  }

  function calloutLogin(response: any) {
    if (response.errorCode) {
      console.log(
        `Login failed! error:${response.errorCode}, errorText:${response.errorText}, playerMessage: ${response.playerMessage}`
      );
    } else {
      console.log('Login success.');
      // setTimeout(() => {
      //   window.open(playUrl, '_blank', `width=${frameDimension?.width}, height=${frameDimension?.height}`);
      // });

      openCenterModal({ url: playUrl, heightSize: frameDimension?.height, widthSize: frameDimension?.width });
    }
  }

  function calloutGetTemporaryAuthenticationToken(response: any) {
    if (response.errorCode) {
      console.log(
        'Temp token failed, error:' +
          response.errorCode +
          ' errorText:' +
          response.errorText +
          ' playerMessage:' +
          response.playerMessage
      );
    } else {
      console.log('Temp token success, token:' + response.sessionToken.sessionToken);
    }
  }

  function calloutLogout(response: any) {
    if (response.errorCode) {
      console.log(
        'Logout failed, error:' +
          response.errorCode +
          ' errorText:' +
          response.errorText +
          ' playerMessage:' +
          response.playerMessage
      );
    } else {
      console.log('Logout success.');
    }
  }
  /* FINE FUNZIONI PLAYTECH */
  //@ts-ignore
  iapiSetCallout('login', calloutLogin);
  //@ts-ignore
  iapiSetCallout('GetTemporaryAuthenticationToken', calloutGetTemporaryAuthenticationToken);
  //@ts-ignore
  iapiSetCallout('Logout', calloutLogout);
  //@ts-ignore
  iapiSetAuthenticationType('password');
  //@ts-ignore
  iapiEnableUserErrors();
  loginPoker(cardNumber);
}

export const launchTotocalcio = async () => {
  const token = await getExternalPlainToken(250);
  //@ts-ignore
  GetInit({
    cID: `${process.env.NEXT_PUBLIC_TOTOCALCIO_CID}`,
    urlSite: `${process.env.NEXT_PUBLIC_TOTOCALCIO_URL}`,
    sID: token,
    OnCompleteBet: '',
    TrackGA: '',
    showticketlisticon: '',
    classTopMobile: '',
  });
};

/*
  @obsolete implementation changet as sisal.runVetrina
*/
export async function createLotterySisalInstance(supplierGame: string) {
  // @ts-ignore
  if (VetrinaGNTN) {
    // @ts-ignore
    let vetrina = new VetrinaGNTN[`${supplierGame}`]('landing_lotterie');
    // @ts-ignore
    const homePage = VetrinaGNTN[`${supplierGame}`].HomePage;
    return { vetrina, homePage };
  }
  return Promise.reject(null);
}

/*
  @obsolete implementation changet as sisal.runVetrina
*/
export async function launchLotterySisal(
  vetrina: any,
  theme: string,
  homePage: any,
  cardNumber?: string,
  externaltoken?: string | null
) {
  vetrina.showGameContent(
    cardNumber,
    externaltoken,
    process.env.NEXT_PUBLIC_SISAL_ID_RIVENDITORE_SISAL, // @ts-ignore
    homePage,
    theme,
    0
  );
}
